import React from 'react'
import { SizedText } from '../../../components/elements/typography'
import { Link } from 'gatsby'

const PoliciesMenu = () => {
  return (
    <div>
            <SizedText as="h2" smallSize="2.281" largeSize="1.802" css={{ padding: `0 1rem 0.5rem 1rem`, fontFamily: `proxima-nova`, borderBottom: `2px solid #000`}}>
              Our Commitment to You
            </SizedText>
            <ul css={{
              listStyle: `none`,
              margin: `0`,
              padding: `0`,
              'li': {
                borderBottom: `1px solid #bdc3c7`,
                'a': {
                  display: `block`,
                  padding: `0.75rem 2rem`,
                  textDecoration: `none`,
                  fontSize: `1.125rem`,
                  color: `#2c3e50`,
                  transition: `all 0.5s ease`,
                  '&:hover': {color: `#3498db`, },
                  '&.active, .active:hover': {fontWeight: `900`, color: `#2c3e50`},
                }
              }
            }}>
              <li><Link to="/about/policies/privacy-policy" activeClassName="active">Privacy Policy</Link></li>
              <li><Link to="/about/policies/fair-lending-policy" activeClassName="active">Fair Lending Policy</Link></li>
              <li><Link to="/about/policies/safe-act" activeClassName="active">SAFE Act</Link></li>
              <li><Link to="/about/policies/patriot-act" activeClassName="active">US PATRIOT Act</Link></li>
              <li><Link to="/about/policies/fdic-coverage" activeClassName="active">FDIC Coverage</Link></li>
              <li><Link to="/about/policies/security" activeClassName="active">Security</Link></li>
              <li><Link to="/about/policies/terms-of-use" activeClassName="active">Terms & Conditions</Link></li>
              <li><Link to="/about/policies/accessibility" activeClassName="active">Website Accessibility</Link></li>
              
            </ul>
          </div>
  )
}

export default PoliciesMenu